import { BrowserRouter as Router } from 'react-router-dom';

import { RoutesConfig } from 'routes';
import { UiProvider } from 'context';

import './App.css';

const App: React.FC = () => (
  <UiProvider>
    <Router>
      <RoutesConfig />
    </Router>
  </UiProvider>
);

export default App;
