import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { useAxios, useUi } from 'hooks';

type DisponibilidadeFormProps = {
  open: boolean;
  onClose: () => void;
};

type FormValues = {
  maxCapacity: number;
  startDate: Date;
  endDate: Date | null;
  startTime: Date;
  endTime: Date;
  dayOfWeekSunday: boolean;
  dayOfWeekMonday: boolean;
  dayOfWeekTuesday: boolean;
  dayOfWeekWednesday: boolean;
  dayOfWeekThursday: boolean;
  dayOfWeekFriday: boolean;
  dayOfWeekSaturday: boolean;
};

type SubmitValues = {
  capacidadeMaxima: number;
  dataInicio: string;
  dataFim?: string;
  horaInicio: string;
  horaFim: string;
  periodo: string;
  tipo: 'SEMANAL';
  localidade: number;
};

const schema = yup.object().shape({
  maxCapacity: yup.number().min(1).max(14).required(),
  startDate: yup.date().required(),
  endDate: yup.string().nullable(),
  startTime: yup.string().required(),
  endTime: yup.string().required(),
});

const defaultStartTime = new Date();
defaultStartTime.setHours(9);
defaultStartTime.setMinutes(0);

const defaultEndTime = new Date();
defaultEndTime.setHours(17);
defaultEndTime.setMinutes(30);

const DisponibilidadeForm: React.FC<DisponibilidadeFormProps> = ({
  open,
  onClose,
}) => {
  const { api } = useAxios();
  const { errorMessage, successMessage } = useUi();
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      maxCapacity: 0,
      startDate: new Date(),
      endDate: null,
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      dayOfWeekSunday: false,
      dayOfWeekMonday: false,
      dayOfWeekTuesday: false,
      dayOfWeekWednesday: false,
      dayOfWeekThursday: false,
      dayOfWeekFriday: false,
      dayOfWeekSaturday: false,
    },
  });

  const closeDialog = (): void => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = ({
    startDate,
    endDate,
    startTime,
    endTime,
    maxCapacity,
    dayOfWeekMonday,
    dayOfWeekTuesday,
    dayOfWeekWednesday,
    dayOfWeekThursday,
    dayOfWeekFriday,
    dayOfWeekSaturday,
    dayOfWeekSunday,
  }) => {
    const periodos = [];

    if (dayOfWeekMonday) {
      periodos.push(1);
    }
    if (dayOfWeekTuesday) {
      periodos.push(2);
    }
    if (dayOfWeekWednesday) {
      periodos.push(3);
    }
    if (dayOfWeekThursday) {
      periodos.push(4);
    }
    if (dayOfWeekFriday) {
      periodos.push(5);
    }
    if (dayOfWeekSaturday) {
      periodos.push(6);
    }
    if (dayOfWeekSunday) {
      periodos.push(7);
    }
    if (periodos.length === 0) {
      errorMessage('É necessário selecionar pelo menos um dia da semana!');
      return;
    }

    const data: SubmitValues = {
      dataInicio: format(startDate, 'dd/MM/yyyy'),
      horaInicio: format(new Date(startTime), 'HH:mm'),
      horaFim: format(new Date(endTime), 'HH:mm'),
      capacidadeMaxima: maxCapacity,
      localidade: 1,
      tipo: 'SEMANAL',
      periodo: periodos.join(','),
    };

    if (endDate) {
      data.dataFim = format(new Date(endDate), 'dd/MM/yyyy');
    }

    api
      .post(`${process.env.REACT_APP_MS_AGENDA_URL}/disponibilidade`, data)
      .then(() => {
        reset();
        successMessage('Disponibilidade registrada com sucesso.');
        onClose();
      })
      .catch(err => {
        if (err.response.status === 400) {
          errorMessage(
            'Revise as informações preenchidas no formulário e tente novamente!',
          );
        }
      });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="md"
      scroll="paper"
    >
      <DialogTitle>Nova Disponibilidade</DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          Registre sua disponibilidade de acordo com os dias e horários
          disponíveis para o local de atendimento.
        </DialogContentText>

        <form>
          <Grid container>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormGroup>
                  <Stack direction={{ xs: 'column', sm: 'row' }}>
                    <Controller
                      name="dayOfWeekSunday"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label="Dom"
                        />
                      )}
                    />
                    <Controller
                      name="dayOfWeekMonday"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label="Seg"
                        />
                      )}
                    />
                    <Controller
                      name="dayOfWeekTuesday"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label="Ter"
                        />
                      )}
                    />
                    <Controller
                      name="dayOfWeekWednesday"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label="Qua"
                        />
                      )}
                    />
                    <Controller
                      name="dayOfWeekThursday"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label="Qui"
                        />
                      )}
                    />
                    <Controller
                      name="dayOfWeekFriday"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label="Sex"
                        />
                      )}
                    />
                    <Controller
                      name="dayOfWeekSaturday"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox />}
                          label="Sáb"
                        />
                      )}
                    />
                  </Stack>
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Defina a data inicial e se necessário a data final.
                </FormLabel>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                      <DesktopDatePicker
                        label="Data Inicial"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        value={field.value}
                        onChange={field.onChange}
                        renderInput={params => <TextField {...params} />}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => (
                      <DesktopDatePicker
                        label="Data Final"
                        inputFormat="dd/MM/yyyy"
                        disablePast
                        value={field.value}
                        onChange={field.onChange}
                        renderInput={params => <TextField {...params} />}
                      />
                    )}
                  />
                </Stack>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Defina o horário inicial e final para os dias selecionados.
                </FormLabel>

                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field }) => (
                      <TimePicker
                        label="Horário Inicial"
                        value={field.value}
                        onChange={field.onChange}
                        renderInput={params => <TextField {...params} />}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field }) => (
                      <TimePicker
                        label="Horário Final"
                        value={field.value}
                        onChange={field.onChange}
                        renderInput={params => <TextField {...params} />}
                      />
                    )}
                  />
                </Stack>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Controller
                name="maxCapacity"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Capacidade Maxima"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <DialogActions>
        <Button type="button" onClick={closeDialog}>
          Cancelar
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisponibilidadeForm;
