import React, { createContext, useState } from 'react';
import {
  Alert,
  AlertColor,
  Backdrop,
  CircularProgress,
  createTheme,
  Snackbar,
  ThemeProvider,
} from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR as dateFnsPtBr } from 'date-fns/locale';

export type Ui = {
  showBackdrop: () => void;
  hideBackdrop: () => void;
  successMessage: (text: string) => void;
  infoMessage: (text: string) => void;
  warnMessage: (text: string) => void;
  errorMessage: (text: string) => void;
};

type UiMessage = {
  open: boolean;
  text: string;
  type: AlertColor;
};

const UiContext = createContext<Ui>({} as Ui);

const UiProvider: React.FC = ({ children }) => {
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [message, setMessage] = useState<UiMessage>({
    open: false,
    text: '',
    type: 'success',
  });

  const showBackdrop = (): void => {
    setIsBackdrop(true);
  };

  const hideBackdrop = (): void => {
    setIsBackdrop(false);
  };

  const showMessage = (text: string, type: AlertColor): void => {
    setMessage({ open: true, text, type });
  };

  const closeMessage = (): void => {
    setMessage(prevState => ({ ...prevState, open: false }));
  };

  const successMessage = (text: string): void => {
    showMessage(text, 'success');
  };

  const infoMessage = (text: string): void => {
    showMessage(text, 'info');
  };

  const warnMessage = (text: string): void => {
    showMessage(text, 'warning');
  };

  const errorMessage = (text: string): void => {
    showMessage(text, 'error');
  };

  const defaultTheme = createTheme(undefined, ptBR);

  return (
    <UiContext.Provider
      value={{
        showBackdrop,
        hideBackdrop,
        successMessage,
        infoMessage,
        warnMessage,
        errorMessage,
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        {isBackdrop && (
          <Backdrop
            open={isBackdrop}
            sx={{
              color: '#fff',
              zIndex: theme => theme.zIndex.drawer + 1,
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Snackbar
          open={message.open}
          onClose={closeMessage}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={closeMessage}
            severity={message.type}
            sx={{ width: '100%' }}
          >
            {message.text}
          </Alert>
        </Snackbar>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={dateFnsPtBr}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </UiContext.Provider>
  );
};

export { UiContext, UiProvider };
