import { useNavigate } from 'react-router-dom';
import { CalendarMonth, Home } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';
import { useMenu } from 'hooks';

const Menu: React.FC = () => {
  const { handleCloseMenu, handleOpenMenu, openMenu } = useMenu();
  const navigate = useNavigate();

  return (
    <SwipeableDrawer
      anchor="left"
      open={openMenu}
      onOpen={handleOpenMenu}
      onClose={handleCloseMenu}
    >
      <Box
        sx={{ width: 350 }}
        role="presentation"
        onClick={handleCloseMenu}
        onKeyDown={handleCloseMenu}
      >
        <List>
          <ListItem button onClick={() => navigate('/')}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => navigate('/disponibilidade')}>
            <ListItemIcon>
              <CalendarMonth />
            </ListItemIcon>
            <ListItemText primary="Disponibilidade" />
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default Menu;
