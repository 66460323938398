import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/disponibilidade');
  }, [navigate]);

  return (
    <section>
      <h1>Home</h1>
    </section>
  );
};

export default Home;
