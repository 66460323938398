import React from 'react';
import { Outlet } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { Box, Container, Grid } from '@mui/material';
import { Header, Menu } from 'components';
import { PrivateLayoutProvider } from 'context';
import { keycloak } from 'services';

const PrivateLayout: React.FC = () => (
  <ReactKeycloakProvider authClient={keycloak}>
    <PrivateLayoutProvider>
      <Box sx={{ width: '100vw', height: '100vh' }}>
        <Header />
        <Menu />

        <Grid
          container
          sx={{ width: '100%', height: '100%' }}
          justifyContent="center"
        >
          <Grid item lg={10} md={9} sm={12}>
            <Box sx={{ mt: 10 }}>
              <Container maxWidth="lg">
                <Outlet />
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PrivateLayoutProvider>
  </ReactKeycloakProvider>
);

export default PrivateLayout;
