import { Box, Grid } from '@mui/material';
import { AnimatedError } from 'components';

const UnexpectedError: React.FC = () => (
  <Box>
    <Grid container display="flex" justifyContent="center" alignItems="center">
      <Box sx={{ width: 'auto' }}>
        <AnimatedError>
          Aconteceu um erro inesperado e já estamos analisando o motivo!
        </AnimatedError>
      </Box>
    </Grid>
  </Box>
);

export default UnexpectedError;
