import { useEffect, useMemo, useState } from 'react';
import { format, lastDayOfMonth, parseISO } from 'date-fns';

import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { DisponibilidadeForm } from 'components';
import { useAxios, useUi } from 'hooks';
import { DesktopDatePicker } from '@mui/x-date-pickers';

type Localidade = {
  nomeClinica: string;
};

type Disponibilidade = {
  id: number;
  capacidadeMaxima: number;
  dataAgendamento: string;
  horaInicio: string;
  horaFim: string;
  localidade: Localidade;
};

const Disponibilidade: React.FC = () => {
  const { api } = useAxios();
  const { showBackdrop, hideBackdrop } = useUi();

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [dataInicial, setDataInicial] = useState<Date | null>(new Date());
  const [dataFinal, setDataFinal] = useState<Date | null>(
    lastDayOfMonth(new Date()),
  );
  const [disponibilidades, setDisponibilidades] = useState<Disponibilidade[]>(
    [],
  );
  const [fetchedData, setFetchedData] = useState(false);

  const handleOpenAddDialog = (): void => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = (): void => {
    setOpenAddDialog(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'dataAgendamento',
      headerName: 'Data do Agendamento',
      flex: 1,
      valueFormatter: params =>
        format(parseISO(params.value as string), 'dd/MM/yyyy'),
    },
    {
      field: 'horaInicio',
      headerName: 'Início',
      flex: 1,
      valueFormatter: params => (params.value as string).slice(0, -3),
    },
    {
      field: 'horaFim',
      headerName: 'Término',
      flex: 1,
      valueFormatter: params => (params.value as string).slice(0, -3),
    },
    {
      field: 'capacidadeMaxima',
      headerName: 'Limite de atendimentos',
      flex: 1,
    },
    {
      field: 'localidade',
      headerName: 'Local',
      flex: 1,
      valueFormatter: params => params.value.nomeClinica,
    },
  ];

  const rows = useMemo(
    () =>
      disponibilidades.map(disponibilidade => ({
        ...disponibilidade,
        id: JSON.stringify(disponibilidade),
      })),
    [disponibilidades],
  );

  const fetchData = (): void => {
    if (!dataInicial || !dataFinal) {
      return;
    }

    const pattern = 'dd/MM/yyyy';
    const body = {
      dataInicial: format(dataInicial, pattern),
      dataFinal: format(dataFinal, pattern),
    };

    showBackdrop();

    api
      .post(
        `${process.env.REACT_APP_MS_AGENDA_URL}/disponibilidade/agenda/profissional`,
        body,
      )
      .then(resp => {
        setDisponibilidades(resp.data);
        setFetchedData(true);
      })
      .catch(err => {
        if (err.response.status === 404) {
          setDisponibilidades([]);
        }
      })
      .finally(() => {
        hideBackdrop();
      });
  };

  useEffect(() => {
    if (openAddDialog === false) {
      fetchData();
    }
  }, [openAddDialog]);

  return (
    <Box>
      <Box>
        <Typography variant="h3">Disponibilidade</Typography>
        <Typography variant="subtitle1" fontWeight={300}>
          Gerencie os dias e horários disponíveis para os seus atendimentos.
        </Typography>
      </Box>

      <Box sx={{ mt: 5 }}>
        {!fetchedData && disponibilidades.length === 0 && (
          <Typography>Nenhuma disponibilidade registrada.</Typography>
        )}

        {fetchedData && (
          <Box>
            <Grid container gap={2} sx={{ mb: 3 }}>
              <DesktopDatePicker
                label="Data Inicial"
                inputFormat="dd/MM/yyyy"
                disablePast
                value={dataInicial}
                onChange={e => setDataInicial(e)}
                renderInput={params => <TextField {...params} />}
              />
              <DesktopDatePicker
                label="Data Final"
                inputFormat="dd/MM/yyyy"
                disablePast
                value={dataFinal}
                onChange={e => setDataFinal(e)}
                renderInput={params => <TextField {...params} />}
              />
              <Button
                onClick={() => fetchData()}
                disabled={!dataInicial || !dataFinal}
              >
                Filtrar
              </Button>
            </Grid>
            <DataGrid
              initialState={{
                pagination: { pageSize: 5 },
              }}
              rows={rows}
              columns={columns}
              rowsPerPageOptions={[5, 10]}
              autoHeight
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 5, mb: 5 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenAddDialog}
        >
          Nova Disponibilidade
        </Button>

        <DisponibilidadeForm
          open={openAddDialog}
          onClose={handleCloseAddDialog}
        />
      </Box>
    </Box>
  );
};

export default Disponibilidade;
