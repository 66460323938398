import { useKeycloak } from '@react-keycloak/web';
import axios, { AxiosInstance } from 'axios';

type UseAxios = {
  api: AxiosInstance;
};

const api = axios.create({ baseURL: process.env.REACT_APP_DEVELOPMENT_URL });

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      console.log('Unauthorized');
    }
    if (error.response.status === 403) {
      console.log('Forbidden');
    }

    return Promise.reject(error);
  },
);

const useAxios = (): UseAxios => {
  const { keycloak } = useKeycloak();

  if (keycloak.token) {
    api.defaults.headers.common = { Authorization: `Bearer ${keycloak.token}` };
  }

  return { api };
};

export default useAxios;
