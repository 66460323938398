import { Routes, Route } from 'react-router-dom';

import { PrivateLayout } from 'components';
import { Disponibilidade, Home, NotFound, UnexpectedError } from 'pages';

const RoutesConfig: React.FC = () => (
  <Routes>
    <Route element={<PrivateLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="/disponibilidade" element={<Disponibilidade />} />

      <Route path="/error" element={<UnexpectedError />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default RoutesConfig;
