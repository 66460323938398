import React, { createContext, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

export type Layout = {
  openMenu: boolean;
  handleOpenMenu: () => void;
  handleCloseMenu: () => void;
};

const PrivateLayoutContext = createContext<Layout>({} as Layout);

const PrivateLayoutProvider: React.FC = ({ children }) => {
  const { initialized, keycloak } = useKeycloak();
  const [openMenu, setOpenMenu] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setIsAuthenticated(false);
      return;
    }
    if (!keycloak.authenticated) {
      keycloak.login();
      return;
    }

    setIsAuthenticated(true);
    console.log('KeycloakProvider loaded');
  }, [initialized, keycloak]);

  const handleOpenMenu = (): void => {
    setOpenMenu(true);
  };

  const handleCloseMenu = (): void => {
    setOpenMenu(false);
  };

  if (!isAuthenticated) {
    console.log('Loading KeycloakProvider');
    return <div>Loading...</div>;
  }

  return (
    <PrivateLayoutContext.Provider
      value={{
        openMenu,
        handleOpenMenu,
        handleCloseMenu,
      }}
    >
      {children}
    </PrivateLayoutContext.Provider>
  );
};

export { PrivateLayoutContext, PrivateLayoutProvider };
